<template>
  <div class="main_layout">
    <div class="logo_content">
      <el-image class="logo-img" :src="require('@/assets/logo.png')"/>
      <div class="text-content" v>
        <div class="logo_text" style="margin-top: -200px">

        </div>
        <div class="logo_text" style="font-size: 60px;font-weight: normal;margin-top: 10px">

        </div>
      </div>
      <div class="copy_right" style="margin-bottom: 20px">
        <div style="font-size: 14px;font-weight: normal;color: #121111">
          Copyright © 2023&nbsp;&nbsp;&nbsp;苏州颜回软件科技有限公司&nbsp;版权所有&nbsp;&nbsp;&nbsp;
          <a data-v-202b8482="" href="http://beian.miit.gov.cn/" target="_blank"
             style="color: #121111;text-decoration: none;font-size: 14px">苏ICP备2023024657号-1</a>
        </div>
      </div>
    </div>
    <div class="login_content">
      <el-form :model="loginForm" ref="loginForm" class="login_form">
        <div class="welcome" style="margin-bottom: 36px">
          <div style="color: #121111;;font-size: 29px">欢迎登录</div>
          <div style="color: #595959;;font-size: 24px;margin-left: 16px">为中小企业创造价值</div>
        </div>
        <div
            style="width: 460px;background-color: white;border-radius: 4px;box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)"
            class="login_layout">
          <div style="color: #595959;;font-size: 20px;margin-top: 55px;width: 395px">CRM客户管理系统</div>
          <div>
            <el-input v-model="loginForm.username" size="normal"
                      style="font-size: 18px;margin-top: 36px"
                      placeholder="请输入账号">
              <i slot="prefix" style="height:100%;display: flex;align-items: center;justify-content: center;">
                <img
                    style="width:20px;height:22px;margin-left: 7px;"
                    src="../assets/user.png"
                    alt
                />
              </i>
            </el-input>

          </div>
          <div>
            <el-input v-model="loginForm.password" size="normal" style="font-size: 18px;margin-top: 36px;"
                      show-password placeholder="请输入登录密码"
                      @keyup.enter.native="submitLogin('loginForm')">
              <i slot="prefix" style="height:100%;display: flex;align-items: center;justify-content: center;">
                <img
                    style="width:20px;height:22px;margin-left: 7px;"
                    src="../assets/pwd.png"
                    alt
                />
              </i>
            </el-input>
          </div>
          <el-button type="primary" @click="submitLogin('loginForm')" size="normal"
                     style="width: 395px;height: 60px;border-radius: 48px;font-size: 24px;margin-top: 68px;margin-bottom: 130px">
            登录
          </el-button>
        </div>
      </el-form>


    </div>
  </div>

</template>

<script>
import qs from 'qs'

export default {
  name: "Login",
  data() {
    return {
      loginForm: {
        username: '',
        password: '',
      }
    }
  },
  methods: {
    handleLang(command) {
      this.lang = command
      let user = {userName: 'zs', pwd: '123'}
      let signIn = {companyPhoto: 'url', signAddress: 'address', longitude: '0', latitude: '0', orderId: '1'}
      let signInDto = {audit: signIn, user: user}
    },
    submitLogin(loginForm) {
      this.$refs[loginForm].validate((valid) => {
        if (valid) {
          const username = this.loginForm.username
          this.$axios.post('/login?' + qs.stringify(this.loginForm)).then(res => {
            console.log(res)
            const jwt = res.headers['authorization']
            console.log(jwt)
            this.$store.commit('SET_TOKEN', jwt)
            this.$store.commit('SET_USERNAME', username)
            this.$router.replace({
              name: 'orderList', params: {
                username: username
              }
            })
          }).catch((error) => {
            console.error(error)
          })

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>

.login_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.copy_right {
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: center;
}


.main_layout {
  width: 100%;
  background-size: 100% 100%;
  height: 100%;
  display: flex;
}

.logo_content {
  flex: 1254;
  background-image: url("../assets/logo_bg.png");
  background-repeat: round;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.text-content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo_text {

  font-size: 80px;
  color: white;
  font-weight: bold;
}

.logo-img {
  width: 220px;
  margin-left: 35px;
  margin-top: 30px;
}

.login_content {
  flex: 666;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.welcome {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.el-input ::v-deep .el-input__inner {
  color: #838383;
  background-color: #F7F8FA;
  height: 57px;
  width: 395px;
  padding-left: 41px;
}

.login_layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
