<template>
  <div class="content_main" style="margin-top: 20px">
    <div class="data1" style="height: 40%">
      <div id="main1" style="width: 45%;height:100%;"></div>
      <div id="main2" style="width: 45%;height:100%;"></div>
    </div>
    <div style="margin-bottom: 20px;padding-left: 10px;padding-right: 10px">
      <hr style="width: 100%; margin: 0px" color="#dddddd" size="0.5"/><!--修改的样式-->
    </div>
    <div class="data1" style="height: 58%">
      <div id="main3" style="width: 55%;height:100%;"></div>
      <div style="width: 50%;height:100%;" class="pay_content">
        <div style="width: 100%;">
          <el-date-picker
              v-model="pager.dateList"
              type="dates"
              size="mini"
              value-format="yyyy-MM-dd"
              placeholder="日期选择"
          />
          <span class="title" style="font-size: 16px;font-weight: bold;margin-left: 5px">
            {{ '  付款客户数: ' + customerCount + '  认证费: ' + cerMoney + '  咨询费: ' + conMoney + '  支出: ' + outMoney }}
          </span>
        </div>

        <div style="margin-left: 10px;width: 100%">
          <el-table
              :row-style="{height: '30px'}"
              :cell-style="{padding: '0px'}"
              ref="multipleTable"
              :data="payOrderList"
              tooltip-effect="dark"
              @cell-dblclick="orderClick"
              style="width: 100%;font-size: 10px;text-align: center;"
          >
            <el-table-column
                align="center"
                label="客户名称"
                width="400">
              <template v-slot="scope">
            <span class="order_item">{{
                scope.row.customer
              }}</span>
              </template>

            </el-table-column>

            <el-table-column
                align="center"
                label="咨询费付款金额"
                width="200">
              <template v-slot="scope">
            <span class="order_item">{{
                scope.row.consultingFee
              }}</span>
              </template>

            </el-table-column>

            <el-table-column
                align="center"
                label="认证费付款金额"
                width="200">
              <template v-slot="scope">
            <span class="order_item">{{
                scope.row.certificationFee
              }}</span>
              </template>

            </el-table-column>

            <el-table-column
                align="center"
                label="报销支出"
            >
              <template v-slot="scope">
            <span class="order_item">{{
                scope.row.outFee
              }}</span>
              </template>

            </el-table-column>

          </el-table>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Statistics",
  data() {
    return {
      payOrderList: [],
      pager: {
        dateList: []
      },
      customerCount: 0,
      cerMoney: 0,
      conMoney: 0,
      outMoney: 0,
    }
  },
  methods: {
    orderClick(row) {

    },
    getOrderList() {
      this.$axios.post('/order/getPayCustomerList', this.pager).then(res => {
        console.log('/order/getPayCustomerList')
        console.log(res)
        this.payOrderList = res.data.data.payCountList
        this.customerCount = res.data.data.customerCount
        this.cerMoney = res.data.data.cerMoney
        this.conMoney = res.data.data.conMoney
        this.outMoney = res.data.data.outMoney
      }).catch((error) => {
        console.error(error)
      })
    },

    satisCerType() {
      this.$axios.post('/order/satisCerType').then(res => {
        console.log('/order/satisCerType')
        console.log(res)
        let data = res.data.data
        this.drawChart3(data)
      }).catch((error) => {
        console.error(error)
      })
    },

    satisOrderType() {
      this.$axios.post('/order/satisOrderType').then(res => {
        console.log('/order/satisOrderType')
        console.log(res)
        let data = res.data.data
        this.drawChart2(data)
      }).catch((error) => {
        console.error(error)
      })
    },

    drawChart2(itemList) {
      //2. 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById('main1'));
      //3. 指定图表的配置项和数据
      var option = {
        title: {
          text: '单据类型分类',
          subtext: '新单/监督审核',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '55%',
            data: itemList,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      //4.使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);

    },

    drawChart3(itemList) {
      //2. 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById('main2'));
      //3. 指定图表的配置项和数据
      var option = {
        title: {
          text: '认证类型',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '55%',
            data: itemList,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      //4.使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);

    },
    drawChart4() {
      //2. 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById('main3'));
      //3. 指定图表的配置项和数据
      var option = {
        title: {
          text: '培训老师订单统计'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          data: ['老师1', '老师2', '老师3', '老师4', '老师5', '老师6', '老师7', '老师8', '老师9', '老师10']
        },
        series: [
          {
            name: '数量',
            type: 'bar',
            data: [18, 19, 20, 21, 15, 13, 11, 14, 22, 19]
          },
          {
            name: '通过数',
            type: 'bar',
            data: [18, 19, 20, 20, 15, 10, 11, 14, 20, 18]
          }
        ]
      };
      //4.使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);

    }
  },
  mounted() {
    this.satisCerType();
    this.satisOrderType();
    this.drawChart4();
  }
}
</script>

<style scoped>

.data1 {
  width: 100%;
  height: ;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
}

.content_main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.pay_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
