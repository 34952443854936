import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    username: ''
  },
  getters: {
    username: state => {
      console.log('getusername')
      if(state.username === undefined){
        state.username = localStorage.getItem("username");
      }
      return state.username
    }
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
      localStorage.setItem("token", token)
    },
    SET_USERNAME: (state, username) => {
      state.username = username
      localStorage.setItem("username", username)
    }
  },
  actions: {
  },
  modules: {

  }
})
