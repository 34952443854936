import Vue from 'vue'
import VueRouter from 'vue-router'
import OrderList from "@/views/OrderList";
import Login from "@/views/Login";
import Statistics from "@/views/Statistics";

let originPush =  VueRouter.prototype.push;  //备份原push方法

VueRouter.prototype.push = function (location, resolve, reject){
  if (resolve && reject) {    //如果传了回调函数，直接使用
    originPush.call(this, location, resolve, reject);
  }else {                     //如果没有传回调函数，手动添加
    originPush.call(this, location, ()=>{}, ()=>{});
  }
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'orderList',
    component: OrderList
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: Statistics
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  let token = localStorage.getItem("token")
  // console.log(token)
  console.log(to.path)
  if (to.path == '/login') {
    next()
  } else if (!token) {
    console.log(token)
    next({path: '/login'})
  }
  next()
})

export default router
